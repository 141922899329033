/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// credit https://alligator.io/gatsbyjs/custom-fonts-in-gatsby/#typefaces.js
require("typeface-poppins")
require("typeface-open-sans")
require("typeface-fira-code")
